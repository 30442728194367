<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5 bg-start-img"
          :style="{ backgroundImage: `url('${imgUrl}')`, backgroundRepeat:'no-repeat', backgroundPosition: 'center' }"
      >

        <!-- Brand logo-->
        <div class="w-100 h-100 d-lg-flex px-1">
          <logo />
        </div>
        <!-- /Brand logo-->

      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg p-2 p-lg-5"
      >
        <b-overlay
          :show="loading"
          variant="transparent"
          opacity="1"
          blur="5px"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ $t('Login.Welcome') }}
            </b-card-title>

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="elon@tesla.co"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  Login
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>{{ $t('Login.new_on_our_platform') }} </span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;{{ $t('Login.Create_account') }}</span>
              </b-link>
            </b-card-text>
          </b-col>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>

    <b-sidebar
      id="sidebar-backdrop"
      :title="$t('Login.select_company')"
      backdrop-variant="dark"
      backdrop
      shadow
      right
      width="50em"
      :visible="showSelectCompany"
      @change="changeShowSelectCompany"
    >
      <div class="px-3 py-2">
        <ul
          id="companies"
          class="list-unstyled"
        >
          <b-media
            v-for="(item, index) in companies"
            :key="index"
            tag="li"
            class="my-4"
          >
            <template #aside>
              <b-link
                v-if="item.logo"
                @click="selectCompany(index)"
              >
                <b-img
                  :src="item.logo"
                  width="64"
                  :alt="item.company"
                />
              </b-link>
            </template>
            <b-link @click="selectCompany(index)">
              <h5 class="mt-0 mb-1">
                {{ item.company }}
              </h5>
            </b-link>
          </b-media>
        </ul>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BOverlay, BFormGroup, BFormInput, BCardTitle, BCardText, BImg, BForm, BButton, VBTooltip, BSidebar, BFormInvalidFeedback, BMedia,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import axios from '@/libs/axios'
import { required, email } from '@validations'
import store from '@/store'
// import { getHomeRouteForLoggedCompany } from '@/auth/utils'
import _ from 'lodash'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BImg,
    BForm,
    BFormInvalidFeedback,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BMedia,
  },
  data() {
    return {
      loading: false,
      status: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-ipad.png'),
      company: null,
      companies: null,
      showSelectCompany: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    urlSlug() {
      return (this.$route.params.slug) ? this.$route.params.slug : ''
    },
    urlParam1() {
      return (this.$route.params.param1) ? this.$route.params.param1 : ''
    },
    urlParam2() {
      return (this.$route.params.param2) ? this.$route.params.param2 : ''
    },
  },
  created() {
    this.checkCompany()
  },
  mounted() {
    window.$crisp.push(["safe", true])
    window.$crisp.do('chat:hide')
  },
  methods: {
    async login() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          this.loading = true
          await axios.get('/sanctum/csrf-cookie')
          await axios.get(`/api/v1/tenants/customers-for-user/${this.userEmail}`)
          .then(async res => {
            // comprobar length de company, si es > 1 debe el usuario elegir empresa
            if (!_.isEmpty(res.data)) { // Hay empresas
              this.companies = res.data
              if (res.data.length > 1) {
                this.showSelectCompany = true
              } else {
                this.selectCompany(0)
              }
            } else {
              this.$refs.loginForm.setErrors({
                email: ['No existen empresas para este email'],
              })
            }
            this.loading = false
          })
          .catch(error => {
            this.$refs.loginForm.setErrors(error.response.data.error)
            this.loading = false
          })
        }
      })
      return true
    },
    async checkCompany() {
      if (!localStorage.getItem('currentSlug') || (this.urlSlug != localStorage.getItem('currentSlug') && this.urlSlug)) {
        if (this.urlSlug) {
          await axios.get('/sanctum/csrf-cookie')
          const { data } = await axios.get(`/api/v1/check-slug/${this.urlSlug}`)

          if (data) {
            localStorage.setItem('currentSlug', data.slug)
            store.commit('auth/SET_COMPANY', data)
            // Redirect to login page
            let params = ''
            if (this.urlParam1) {
              params = `${this.urlParam1}`
              if (this.urlParam2) params = `${params}/${this.urlParam2}`
            } else {
              params = 'login'
            }
            window.location.replace(`${process.env.VUE_APP_URL}/${data.slug}/${params}`)
          }
        }
      } else {
        // Redirect to login page
        let params = ''
        if (this.urlParam1) {
          params = `${params}/${this.urlParam1}`
          if (this.urlParam2) params = `${params}/${this.urlParam2}`
        } else {
          params = 'login'
        }
        this.$router.push({ path: `/${params}` })
      }
    },
    selectCompany(key) {
      this.company = this.companies[key]
      if (this.company) {
        // guardar datos de Company del usuario en store
        localStorage.setItem('currentSlug', this.company.slug)
        store.commit('auth/SET_COMPANY', this.company)
        window.$crisp.set('user:company', [this.company.company])

        window.location.replace(`${process.env.VUE_APP_URL}/${this.company.slug}/login`)
      } else {
        this.$refs.loginForm.setErrors({
          email: 'No existen empresas para este email',
        })
      }
    },
    changeShowSelectCompany(val) {
      this.showSelectCompany = val
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.bg-start-img{
  background: no-repeat center center;
  background-size: auto auto;
}
</style>
